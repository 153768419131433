
import React from "react"
import { useState } from "react";
import Button from 'react-bootstrap/Button';

import { Col } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import Table from 'react-bootstrap/Table';
import "react-toastify/dist/ReactToastify.css";
import { Card, Accordion, } from 'react-bootstrap';
import { Badge } from 'antd';
import useImageHoverStore from "./store/useImageHoverStore";
import { Popover } from 'antd';
import Form from 'react-bootstrap/Form';
import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import useGetPendingProducts from "./hooks/useGetPendingProducts";
export const ProductApprovalPanel = ({ product }) => {
    const { show, src, setSrc, setShow } = useImageHoverStore();
    const [open, setOpen] = useState(false);
    const [reason, setReason] = useState("");
    const {
        refetch
    } = useGetPendingProducts(0);
    const hide = () => {
        setOpen(false);
    };
    const handleOpenChange = (newOpen) => {
        setOpen(newOpen);
    };

    const migrateProduct = (option) => {
        const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/admin-dashboard/products/migrate-product`
        const finalData = {
            product_id: product.product_id,
            option: option,
            ...(option === "reject" ? { reject_reason: reason } : {})
        }
        return axios.post(finalUrl, finalData, { withCredentials: true })
    }
    const migrateProductMutaion = useMutation({
        mutationFn: migrateProduct,
        onSuccess: () => {
            setReason("")
            hide()
            toast.success("Done")
            refetch()
        },
        onError: (error) => {
            console.log(error);
            toast.error("Error")
        }
    })



    return (
        <Card style={{ width: "50vw" }} className="mb-4 ">
            <Card.Header className="d-flex justify-content-between align-items-center">
                <h3>Product Name: {product?.product_name}</h3>
                <Col className="d-flex justify-content-end align-items-center">
                    <Popover
                        content={
                            <>
                                <Form onSubmit={(e) => {
                                    e.preventDefault()
                                    migrateProductMutaion.mutate("reject")
                                }}>

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                        <Form.Label>reason</Form.Label>
                                        <Form.Control value={reason} onChange={(e)=>setReason(e.target.value)} required as="textarea" rows={3} />
                                    </Form.Group>
                                    <Button
                                        type="submit"
                                        variant="primary"
                                        className="me-2"
                                    >
                                        send
                                    </Button>
                                </Form>
                            </>

                        }
                        trigger="click"
                        open={open}
                        onOpenChange={handleOpenChange}
                    >

                        <Button
                            variant="danger"
                            className="me-2"
                        >
                            Decline
                        </Button>
                    </Popover>
                    <Button
                        variant="success"
                        onClick={() => {
                            migrateProductMutaion.mutate("accept")
                        }}
                    >
                        Approved
                    </Button>
                </Col>
            </Card.Header>
            <Card.Body>
                <Table striped bordered hover>
                    <tbody>
                        <tr><td><strong>Product ID:</strong></td><td>{product?.product_id}</td></tr>
                        <tr><td><strong>Description:</strong></td><td>{product?.description}</td></tr>
                        <tr><td><strong>Brand:</strong></td><td>{product?.product_brand}</td></tr>
                        <tr><td><strong>Features:</strong></td><td>{product?.features}</td></tr>
                        <tr><td><strong>Category:</strong></td><td>{product?.category_name} (ID: {product?.category_id})</td></tr>
                        <tr><td><strong>Store:</strong></td><td>{product?.store_name} (Seller ID: {product?.seller_id})</td></tr>
                    </tbody>
                </Table>

                <Image src={product?.seller_logo} thumbnail className="mb-3" style={{ maxWidth: '150px' }} />

                <Accordion>
                    {product.product_variations.map((variation, index) => (
                        <Accordion.Item eventKey={index.toString()} key={variation?.variation_id}>
                            <Accordion.Header>
                                Variation {index + 1}: {variation?.color}
                                <Badge className="ms-2" color={variation?.color_hex} />

                            </Accordion.Header>
                            <Accordion.Body>
                                <Table striped bordered hover size="sm">
                                    <thead>
                                        <tr>
                                            <th>Size</th>
                                            <th>Stock Quantity</th>
                                            <th>Price (Butiko)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {variation.size_details.map((size) => (
                                            <tr key={size?.size_id}>
                                                <td>{size?.size}</td>
                                                <td>{size?.stock_quantity}</td>
                                                <td>{size?.price_in_butiko}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                {variation?.variation_media.map((media, mediaIndex) => (
                                    <>
                                        <Image
                                            key={mediaIndex}
                                            src={media}
                                            thumbnail
                                            className="mt-2 me-2"
                                            style={{ maxWidth: '200px' }}
                                            onClick={() => {
                                                setSrc(media)
                                                setShow(true)
                                            }}
                                        />

                                    </>

                                ))}
                            </Accordion.Body>
                        </Accordion.Item>
                    ))}
                </Accordion>
            </Card.Body>
        </Card>
    );
};