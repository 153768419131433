import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer } from 'react-toastify';
import Col from 'react-bootstrap/Col';
import { useState } from 'react';
import useReports from '../hooks/useReports';
import { Tabs, Tab, ListGroup, Carousel } from 'react-bootstrap';
import { Badge } from 'antd';
import "../style.css"
import { Skeleton } from "antd";
const ReportModal = ({ show, onHide, productId }) => {
    const [page, setPage] = useState(0);
    const [activeTab, setActiveTab] = useState('details');
    const [activeVariation, setActiveVariation] = useState(0);
    const {
        reports,
        isLoading,
    } = useReports(productId, page);
    const handleBack = () => {
        if (page !== 0) {
            setPage(page - 1);
        }
    }
    const handleNext = () => {
        if (reports?.length === 11) {
            setPage(page + 1);
        }
    }
    console.log(reports);
    const product = reports?.product_details;
    return (
        <>
            {productId !== 0 && (
                <Modal size='xl' show={show} onHide={onHide}>
                    <Modal.Header closeButton>
                        <Modal.Title>Reports</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {isLoading ? (
                            <>
                                <Skeleton active />
                            </>
                        ) : (<Tabs
                            activeKey={activeTab}
                            onSelect={(k) => setActiveTab(k)}
                            className="mb-3"
                        >
                            <Tab eventKey="details" title="Product Details">
                                <p><strong>Description:</strong> {product?.description}</p>
                                <p><strong>Category:</strong> {product?.category_name}</p>

                                <h5>Variations</h5>
                                {product?.variation_details.map((variation, index) => (
                                    <div onClick={() => setActiveVariation(index)} style={{ cursor: "pointer" }} key={index} className="mb-2">
                                        {/* <Badge
                                        style={{ backgroundColor: `${variation.color_hex}!important`, color: '#fff' }}
                                        onClick={() => setActiveVariation(index)}
                                    >
                                        {variation.color_name}
                                    </Badge>
                                    {' '} */}
                                        <Badge color={variation.color_hex} text={variation.color_name} />{" "}
                                        {variation.variation_medias.length} image(s)
                                    </div>
                                ))}
                                <Carousel className=' bg-secondary'>
                                    {product?.variation_details[activeVariation]?.variation_medias?.map((url, index) => (
                                        <Carousel.Item key={index}>
                                            {/* <Image
                                            className="d-block w-100"
                                            src={url}
                                            alt={`${product?.name} - ${product?.variation_details[activeVariation]?.color_name}`}
                                            
                                            width="200"

                                        /> */}
                                            <img
                                                className="d-block w-100"
                                                src={url}
                                                alt={`${product.name} - ${product.variation_details[activeVariation].color_name}`}
                                                style={{ maxHeight: '300px', objectFit: 'contain' }}
                                            />
                                        </Carousel.Item>
                                    ))}
                                </Carousel>
                                <h5 className="mt-3">Sizes</h5>
                                <ListGroup>
                                    {product?.size_details?.map((size, index) => (
                                        <ListGroup.Item key={index}>
                                            Size: {size.size}, Price: ₺{size.price_in_butiko}
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>
                            </Tab>

                            <Tab eventKey="reports" title="Reports">
                                <ListGroup>
                                    {reports?.reports?.map((report) => (
                                        <ListGroup.Item key={report.id}>
                                            <h6>{report.report_title}</h6>
                                            <p>{report.report_content}</p>
                                            <small>Created at: {new Date(report.created_at).toLocaleString()}</small>
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>
                                <Col className='d-flex justify-content-end mt-3'>
                                    <Button onClick={handleBack} className='me-1'>back</Button>
                                    <Button onClick={handleNext}>next</Button>
                                </Col>
                            </Tab>
                        </Tabs>)}
                    </Modal.Body>

                    <ToastContainer />
                </Modal>
            )}
        </>
    )
}
export default ReportModal