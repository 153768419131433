import React, { useMemo } from "react"
import { useState } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { IoChevronBack } from "react-icons/io5";
import 'react-quill/dist/quill.snow.css';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import useGetDebts from "./hooks/useGetDebts";
import useGetTokens from "./hooks/useGetTokens";
import { useQueries } from "@tanstack/react-query";
import ErrorModal from "./modals/ErrorModal";
import axios from "axios";
import formatDate from "../../components/formatDate";
import { Spin } from 'antd';
import Spinner from 'react-bootstrap/Spinner';

const Transfer = () => {
    const [debtPage, setDabtPage] = useState(0);
    const [tokenPage, setTokenPage] = useState(0);
    const [debtOn, setDebtOn] = useState(false);
    const [tokenOn, setTokenOn] = useState(false);
    const [show, setShow] = useState(false);

    const {
        debts,
        isLoading,
        isError,
        error,
        refetch,
        isNext,
        debtIds

    } = useGetDebts(debtPage)
    console.log(debtIds);
    const {
        tokens,
        isLoading: tokenIsLoading,
        isError: tokenIsError,
        error: tokenError,
        refetch: tokenRefetch,
        isNext: tokenIsNext,
        tokenIds
    } = useGetTokens(tokenPage)
    console.log(tokenIds);

    let ids = [1, 2, 3, 4, 5, 6, 7, 8, "gfht", 10]
    // ------------------------------------------------------
    const transferToken = async (id) => {
        const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/admin-dashboard/transactions/transfer-fund-token`
        const finalData = {
            transaction_id: id
        }
        return await axios.post(finalUrl, finalData, { withCredentials: true })

    };
    const transferTokenQuery = useQueries({
        queries: tokenIds.map((id) => ({
            queryKey: ['post', id],
            queryFn: () => transferToken(id),
            enabled: tokenOn,
            retry: 1
        }))

    })
    console.log(transferTokenQuery);
    const transferTokenIsLoading = transferTokenQuery.some((query) => query.isLoading);
    const transferTokenIsSuccess = transferTokenQuery.length > 0 && transferTokenQuery.every((query) => query?.data?.info === "funds transferred successfully");
    const transferTokenIsError = transferTokenQuery.map((query) => query?.error ? query?.error : "no msg");
    let transferTokenOneError = transferTokenQuery.some((query) => query.isError);
    useMemo(() => {
        if (transferTokenOneError) {
            setShow(true)
            setTokenOn(false)
            tokenRefetch()
        }

    }, [transferTokenOneError])
    useMemo(() => {
        if (transferTokenIsSuccess) {

            toast.success("done", { toastId: "token-success" })
            tokenRefetch()
        }

    }, [transferTokenIsSuccess])
    // -------------------------------------------------------
    const transferDebt = async (id) => {
        const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/admin-dashboard/transactions/transfer-fund-debt`
        const finalData = {
            order_transaction_id: id
        }
        return await axios.post(finalUrl, finalData, { withCredentials: true })

    };
    const transferDebtQuery = useQueries({
        queries: debtIds.map((id) => ({
            queryKey: ['post', id],
            queryFn: () => transferDebt(id),
            enabled: debtOn,
            retry: 1
        }))

    })
    console.log(transferDebtQuery);
    const transferDebtIsLoading = transferDebtQuery.some((query) => query.isLoading);
    const transferDebtIsSuccess = transferDebtQuery.length > 0 &&transferDebtQuery.every((query) => query?.data?.info === "funds transferred successfully");
    const transferDebtIsError = transferDebtQuery.map((query) => query?.error ? query?.error : "no msg");
    let transferDebtOneError = transferDebtQuery.some((query) => query.isError);
    useMemo(() => {
        if (transferDebtOneError) {
            setShow(true)
            setDebtOn(false)
            refetch()
        }
    }, [transferDebtOneError])
    useMemo(() => {
        if (transferDebtIsSuccess ) {

            toast.success("done", { toastId: "Debt-success" })
            refetch()
        }

    }, [transferDebtIsSuccess])
    // -------------------------------------------------------
    return (
        <>
            <Container className="d-flex justify-content-center ">
                <Row className="flex justify-start ">
                    <Col className="flex justify-start ">
                        <Link
                            style={{ position: "absolute", top: "5px ", left: "0", padding: "16px" }}
                            to={"/nextpageoflogin"}
                        >
                            <IoChevronBack color="#000" size={50} />
                        </Link>
                    </Col>
                </Row>
                <div className="container mx-auto p-6 overflow-x-auto rounded-t-lg  custom-scrollbar ">
                    <h1>transfer Page</h1>
                    {/* <button className="border p-4 bg-blue-700 text-white" onClick={() => setDebtOn(!debtOn)} >{String(debtOn)}</button> */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        {/* First Column */}
                        <div className="bg-white shadow-md rounded-lg p-4">
                            <div className="bg-white shadow-sm rounded-lg p-2 overflow-x-auto">
                                <h2 className="text-lg font-bold mb-2">Debts</h2>
                                <table className="w-full text-sm">
                                    <thead>
                                        <tr className="bg-gray-100">
                                            <th className="p-2 text-left">ID</th>
                                            <th className="p-2 text-left">Debt</th>
                                            <th className="p-2 text-left">Created</th>
                                            <th className="p-2 text-left">Updated</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {isLoading ? (
                                            <tr >
                                                {[...Array(4)].map((_, colIndex) => (
                                                    <td key={colIndex} className="px-6 py-4 border-b border-gray-200">
                                                        <div className="h-4 bg-gray-200 rounded animate-pulse"></div>
                                                    </td>
                                                ))}
                                            </tr>
                                        ) : (
                                            Array.isArray(debts) && debts.map((item, index) => (
                                                <tr key={index} className="border-b border-gray-200">
                                                    <td className="p-2">{item.order_transaction_id}</td>
                                                    <td className="p-2">{item.debt}</td>
                                                    <td className="p-2">{formatDate(item.created_at)}</td>
                                                    <td className="p-2">{formatDate(item.updated_at)}</td>
                                                </tr>
                                            ))
                                        )}
                                    </tbody>
                                </table>
                                <div className="flex justify-between">
                                    <button onClick={() => {
                                        if (debtPage !== 0) {
                                            setDabtPage((prev) => prev--)
                                        }
                                    }} className={`${debtPage === 0 ? "hover:cursor-not-allowed" : ""} mt-2  bg-gray-500 hover:bg-gray-700 text-white font-bold py-1 px-3 rounded text-sm`}>
                                        Prev
                                    </button>
                                    <button
                                        disabled={!isNext}
                                        onClick={() => setDabtPage((prev) => prev++)}
                                        className={`disabled:cursor-not-allowed mt-2 bg-gray-500 hover:bg-gray-700 text-white font-bold py-1 px-3 rounded text-sm`}
                                    >
                                        Next
                                    </button>
                                </div>
                                <div className="text-end mr-2">
                                    <button onClick={() => setDebtOn(!debtOn)} className={` items-center justify-end mt-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-3 rounded text-sm`}>
                                        {transferDebtIsLoading? <Spinner animation="border" /> : "Transfer Debt"}
                                    </button>
                                </div>
                            </div>
                        </div>
                        {/* Second Column */}
                        <div className="bg-white shadow-md rounded-lg p-4">
                            <div className="bg-white shadow-sm rounded-lg p-2 overflow-x-auto">
                                <h2 className="text-lg font-bold mb-2">Tokens</h2>
                                <table className="w-full text-sm">
                                    <thead>
                                        <tr className="bg-gray-100">
                                            <th className="p-2 text-left">ID</th>
                                            <th className="p-2 text-left">payment</th>
                                            <th className="p-2 text-left">Created</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tokenIsLoading ? (
                                            <tr >
                                                {[...Array(3)].map((_, colIndex) => (
                                                    <td key={colIndex} className="px-6 py-4 border-b border-gray-200">
                                                        <div className="h-4 bg-gray-200 rounded animate-pulse"></div>
                                                    </td>
                                                ))}
                                            </tr>
                                        ) : (
                                            Array.isArray(tokens) && tokens.map((item, index) => (
                                                <tr key={index} className="border-b border-gray-200">
                                                    <td className="p-2">{item.transaction_id}</td>
                                                    <td className="p-2">{item.payment}</td>
                                                    <td className="p-2">{formatDate(item.created_at)}</td>
                                                </tr>
                                            ))
                                        )}
                                    </tbody>
                                </table>
                                <div className="flex justify-between">
                                    <button onClick={() => {
                                        if (tokenPage !== 0) {
                                            setTokenPage((prev) => prev--)
                                        }
                                    }} className={`${tokenPage === 0 ? "hover:cursor-not-allowed" : ""} mt-2  bg-gray-500 hover:bg-gray-700 text-white font-bold py-1 px-3 rounded text-sm`}>
                                        Prev
                                    </button>
                                    <button
                                        onClick={() => setTokenPage((prev) => prev++)}
                                        disabled={!tokenIsNext}
                                        className={` disabled:cursor-not-allowed mt-2 bg-gray-500 hover:bg-gray-700 text-white font-bold py-1 px-3 rounded text-sm`}
                                    >
                                        Next
                                    </button>
                                </div>
                                <div className="text-end mr-2">
                                    <button
                                        onClick={() => setTokenOn(!tokenOn)}
                                        className={` mt-2 bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-3 rounded text-sm`}
                                    >
                                        {transferTokenIsLoading? <Spinner animation="border" />: "Transfer Token"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ErrorModal show={show} setShow={setShow} errors={transferDebtIsError} tokenErrors={transferTokenIsError} />
                <ToastContainer />
            </Container>
        </>
    )
}
export default Transfer