import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function ErrorModal({ show, setShow, errors, oneError,tokenErrors }) {
    // const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false)
        oneError = false
    };
    const handleShow = () => setShow(true);
    // if (oneError) {
    //     handleShow()
    // }
    console.log(errors);
    // console.log(oneError);

    return (
        <>
            {/* <Button variant="primary" onClick={handleShow}>
                Launch demo modal
            </Button> */}

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Modal heading</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="bg-red-50 border-l-4 border-red-400 p-4 mb-4 rounded-r-lg shadow-md">
                        <div className="flex items-start">
                            <div className="flex-shrink-0">
                                <svg className="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                                </svg>
                            </div>
                            <div className="ml-3 flex-1">
                                <h3 className="text-sm font-medium text-red-800">Error Details</h3>
                                <div className="mt-2 text-sm text-red-700">
                                    {Array.isArray(errors) && errors?.map((item) => {
                                        let debtError = ""
                                        let tokenError = ""
                                        try {
                                            let errorId = JSON.parse(item?.config?.data)
                                            debtError = errorId?.order_transaction_id
                                            tokenError = errorId?.transaction_id
                                        } catch (e) { }
                                        return (<>
                                            <h1> </h1>

                                            <span className="font-semibold">{debtError ? debtError : tokenError}</span>
                                            <span className="ml-2">{item?.response?.data?.detail[0]?.msg}</span>
                                        </>)
                                    })}
                                    {Array.isArray(tokenErrors) && tokenErrors?.map((item) => {
                                        let debtError = ""
                                        let tokenError = ""
                                        try {
                                            let errorId = JSON.parse(item?.config?.data)
                                            debtError = errorId?.order_transaction_id
                                            tokenError = errorId?.transaction_id
                                        } catch (e) { }
                                        return (<>
                                            <h1> </h1>

                                            <span className="font-semibold">{debtError ? debtError : tokenError}</span>
                                            <span className="ml-2">{item?.response?.data?.detail[0]?.msg}</span>
                                        </>)
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ErrorModal;