import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import { useQuery, useMutation } from '@tanstack/react-query';
import Table from 'react-bootstrap/Table';
import { toast } from 'react-toastify';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


const DocDetailModal = ({ companyTaxOffice, companyMersisNo, companyRegistrationNo, taxNo, Tc, companyTitle, companyType, id, show, onHide }) => {

    const sellerPendingDocs = () => {
        const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/admin-dashboard/file/get-seller-data?seller_id=${id}`
        return axios.get(finalUrl, { withCredentials: true })
    }
    const { isLoading, isError, error, data, refetch } = useQuery({ queryKey: ["get-pending-docs", id], queryFn: sellerPendingDocs })
    let pendingDoc
    if (isLoading) {
        pendingDoc = "loading"
    } else if (isError) {
        console.log(error)
        pendingDoc = "error"
    } else {
        pendingDoc = data?.data
        console.log(pendingDoc);
    }
    const downloadDoc = async (id, type) => {
        console.log(id)
        console.log(type)
        try {
            const fileUrl = `${process.env.REACT_APP_API_URL}/api/v1/admin-dashboard/file/get-file`
            const finalData = {
                seller_id: id,
                file_type: type
            }
            const response = await axios.post(fileUrl, finalData, {
                withCredentials: true,
                responseType: "blob"
            })
            const mimeType = response.headers['content-type'];
            const file = new Blob([response.data], { type: mimeType })
            const fileUrlObject = URL.createObjectURL(file)
            window.open(fileUrlObject, "_blank")
        } catch (error) {
            if (data?.data[type] === null) {
                toast.error("dosya yok", { toastId: "noFile" })
            }
            toast.error("dosya yok", { toastId: "noFile" })
            console.log(error);
        }
    }

    const updateDocStatus = (props) => {
        const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/admin-dashboard/file/accept-decline-file`
        const data = {
            seller_id: props.id,
            file_type: props.type,
            decision: props.decision
        }
        return axios.post(finalUrl, data, { withCredentials: true })
    }
    const updateDocStatusMutation = useMutation({
        mutationFn: updateDocStatus,
        onSuccess: () => {
            toast.success("Status updated ", { toastId: "statusUpdated" })
            refetch()
        },
        onError: (error) => {
            console.log(error);
            toast.error("An error occurred", { toastId: "errorToast" });
            refetch()
        }
    })

    return (
        <>
            <Modal size='xl' show={show} onHide={onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>Documents</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className='bg-light'>
                        <Col sm={6}>
                            <p><strong>Company Tax Office:</strong> {companyTaxOffice}</p>
                        </Col>
                        <Col sm={6}>
                            <p><strong>Tax Number:</strong> {taxNo}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            <p><strong>Company Mersis No:</strong> {companyMersisNo}</p>
                        </Col>
                        <Col sm={6}>
                            <p><strong>Company Registration No:</strong> {companyRegistrationNo}</p>
                        </Col>
                    </Row>
                    <Row className='bg-light'>
                        <Col sm={6}>
                            <p><strong>TC:</strong> {Tc}</p>
                        </Col>
                        <Col sm={6}>
                            <p><strong>Company Title:</strong> {companyTitle}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            <p><strong>Company Type:</strong> {companyType}</p>
                        </Col>
                    </Row>

                    <Table striped bordered hover>
                        <thead >
                            <tr>
                                <th>Document Type</th>
                                <th>Status</th>
                                <th>Download</th>
                                <th>Approved</th>
                                <th>Decline</th>
                            </tr>
                        </thead>
                        <tbody>
                            {pendingDoc && (
                                <>

                                    <tr>
                                        <td>Tax board</td>
                                        <td>{pendingDoc.status_tax_board}</td>
                                        <td>
                                            <Button
                                                onClick={() => {
                                                    downloadDoc(id, "tax_board")
                                                }}
                                            >
                                                Download
                                            </Button>
                                        </td>
                                        {pendingDoc.status_tax_board === "approved" ? (
                                            <>

                                                <td> approved</td>
                                                <td> approved </td>
                                            </>
                                        ) : pendingDoc.status_tax_board === "declined" ? (null) : (
                                            <>

                                                <td>
                                                    <Button onClick={() => {
                                                        updateDocStatusMutation.mutate({ id: id, type: "tax_board", decision: "approved" })
                                                    }} variant="success">
                                                        Approve
                                                    </Button>
                                                </td>
                                                <td>
                                                    <Button onClick={() => {
                                                        updateDocStatusMutation.mutate({ id: id, type: "tax_board", decision: "declined" })
                                                    }} variant="danger">
                                                        Decline
                                                    </Button>
                                                </td>
                                            </>
                                        )}

                                    </tr>
                                    <tr>
                                        <td>Activity certificate</td>
                                        <td>{pendingDoc.status_activity_certificate}</td>
                                        <td>
                                            <Button
                                                onClick={() => {
                                                    downloadDoc(id, "activity_certificate")
                                                }}
                                            >
                                                Download
                                            </Button>
                                        </td>
                                        {pendingDoc.status_activity_certificate === "approved" ? (
                                            <>

                                                <td> approved</td>
                                                <td> approved </td>
                                            </>
                                        ) : pendingDoc.status_activity_certificate === "declined" ? (null) : (
                                            <>

                                                <td>
                                                    <Button onClick={() => {
                                                        updateDocStatusMutation.mutate({ id: id, type: "activity_certificate", decision: "approved" })
                                                    }} variant="success">
                                                        Approve
                                                    </Button>
                                                </td>
                                                <td>
                                                    <Button onClick={() => {
                                                        updateDocStatusMutation.mutate({ id: id, type: "activity_certificate", decision: "declined" })
                                                    }} variant="danger">
                                                        Decline
                                                    </Button>
                                                </td>
                                            </>
                                        )}

                                    </tr>
                                    <tr>
                                        <td>Trade registry gazette</td>
                                        <td>{pendingDoc.status_trade_registry_gazette}</td>
                                        <td>
                                            <Button
                                                onClick={() => {
                                                    downloadDoc(id, "trade_registry_gazette")
                                                }}
                                            >
                                                Download
                                            </Button>
                                        </td>
                                        {pendingDoc.status_trade_registry_gazette === "approved" ? (
                                            <>

                                                <td> approved</td>
                                                <td> approved </td>
                                            </>
                                        ) : pendingDoc.status_trade_registry_gazette === "declined" ? (null) : (
                                            <>

                                                <td>
                                                    <Button onClick={() => {
                                                        updateDocStatusMutation.mutate({ id: id, type: "trade_registry_gazette", decision: "approved" })
                                                    }} variant="success">
                                                        Approve
                                                    </Button>
                                                </td>
                                                <td>
                                                    <Button onClick={() => {
                                                        updateDocStatusMutation.mutate({ id: id, type: "trade_registry_gazette", decision: "declined" })
                                                    }} variant="danger">
                                                        Decline
                                                    </Button>
                                                </td>
                                            </>
                                        )}

                                    </tr>
                                    <tr>
                                        <td>Authorized signatures</td>
                                        <td>{pendingDoc.status_authorized_signatures}</td>
                                        <td>
                                            <Button
                                                onClick={() => {
                                                    downloadDoc(id, "authorized_signatures")
                                                }}
                                            >
                                                Download
                                            </Button>
                                        </td>
                                        {pendingDoc.status_authorized_signatures === "approved" ? (
                                            <>

                                                <td> approved</td>
                                                <td> approved </td>
                                            </>
                                        ) : pendingDoc.status_authorized_signatures === "declined" ? (null) : (
                                            <>

                                                <td>
                                                    <Button onClick={() => {
                                                        updateDocStatusMutation.mutate({ id: id, type: "authorized_signatures", decision: "approved" })
                                                    }} variant="success">
                                                        Approve
                                                    </Button>
                                                </td>
                                                <td>
                                                    <Button onClick={() => {
                                                        updateDocStatusMutation.mutate({ id: id, type: "authorized_signatures", decision: "declined" })
                                                    }} variant="danger">
                                                        Decline
                                                    </Button>
                                                </td>
                                            </>
                                        )}

                                    </tr>
                                    <tr>
                                        <td>Smart ewallet contract</td>
                                        <td>{pendingDoc.status_smart_ewallet_contract}</td>
                                        <td>
                                            <Button
                                                onClick={() => {
                                                    downloadDoc(id, "smart_ewallet_contract")
                                                }}
                                            >
                                                Download
                                            </Button>
                                        </td>
                                        {pendingDoc.status_smart_ewallet_contract === "approved" ? (
                                            <>

                                                <td> approved</td>
                                                <td> approved </td>
                                            </>
                                        ) : pendingDoc.status_smart_ewallet_contract === "declined" ? (null) : (
                                            <>

                                                <td>
                                                    <Button onClick={() => {
                                                        updateDocStatusMutation.mutate({ id: id, type: "smart_ewallet_contract", decision: "approved" })
                                                    }} variant="success">
                                                        Approve
                                                    </Button>
                                                </td>
                                                <td>
                                                    <Button onClick={() => {
                                                        updateDocStatusMutation.mutate({ id: id, type: "smart_ewallet_contract", decision: "declined" })
                                                    }} variant="danger">
                                                        Decline
                                                    </Button>
                                                </td>
                                            </>
                                        )}

                                    </tr>
                                    <tr>
                                        <td>Seller contract</td>
                                        <td>{pendingDoc.status_seller_contract}</td>
                                        <td>
                                            <Button
                                                onClick={() => {
                                                    downloadDoc(id, "seller_contract")
                                                }}
                                            >
                                                Download
                                            </Button>
                                        </td>
                                        {pendingDoc.status_seller_contract === "approved" ? (
                                            <>

                                                <td> approved</td>
                                                <td> approved </td>
                                            </>
                                        ) : pendingDoc.status_seller_contract === "approved" ? (null) : (
                                            <>

                                                <td>
                                                    <Button onClick={() => {
                                                        updateDocStatusMutation.mutate({ id: id, type: "seller_contract", decision: "approved" })
                                                    }} variant="success">
                                                        Approve
                                                    </Button>
                                                </td>

                                                <td>
                                                    <Button onClick={() => {
                                                        updateDocStatusMutation.mutate({ id: id, type: "seller_contract", decision: "declined" })
                                                    }} variant="danger">
                                                        Decline
                                                    </Button>
                                                </td>
                                            </>
                                        )}

                                    </tr>
                                    <tr>
                                        <td>TC photo</td>
                                        <td>{pendingDoc.status_TC_photo}</td>
                                        <td>
                                            <Button
                                                onClick={() => {
                                                    downloadDoc(id, "TC_photo")
                                                }}
                                            >
                                                Download
                                            </Button>
                                        </td>
                                        {pendingDoc.status_TC_photo === "approved" ? (
                                            <>

                                                <td> approved</td>
                                                <td> approved </td>
                                            </>
                                        ) : pendingDoc.status_TC_photo === "declined" ? (null) : (
                                            <>

                                                <td>
                                                    <Button onClick={() => {
                                                        updateDocStatusMutation.mutate({ id: id, type: "TC_photo", decision: "approved" })
                                                    }} variant="success">
                                                        Approve
                                                    </Button>
                                                </td>
                                                <td>
                                                    <Button onClick={() => {
                                                        updateDocStatusMutation.mutate({ id: id, type: "TC_photo", decision: "declined" })
                                                    }} variant="danger">
                                                        Decline
                                                    </Button>
                                                </td>
                                            </>
                                        )}

                                    </tr>

                                </>

                            )}
                        </tbody>
                    </Table>
                </Modal.Body>

                {/* <ToastContainer /> */}
            </Modal>
        </>
    )
}
export default DocDetailModal