import Invoice from '../pages/Invoice/Invoice.js';
import Login from '../pages/Login/Login.js';
import NextPageOfLogin from '../pages/NextPageOfLogin/NextPageOfLogin.js';
import NotificationPage from '../pages/Notification/Notification.js';
import Token from '../pages/Token/Token.js';
import Chat from '../pages/Crm/chat.js';
import Document from '../pages/Documents/Document.js';
import React from "react"
import Report from '../pages/report/report.js';
import ProductApprove from '../pages/ProductApprove/ProductApprove.js';
import Transactions from '../pages/Transactions/Transactions.js';
import Transfer from '../pages/Transfer/Transfer.js';
// import { Navigate } from "react-router-dom"
const authProtectedRoutes =[
    {path: "/invoice" , component: <Invoice />},
    {path: "/nextpageoflogin" , component: <NextPageOfLogin />},
    {path: "/notification" , component: <NotificationPage />},
    {path: "/token" , component: <Token />},
    {path: "/crm" , component: <Chat />},
    {path: "/doc" , component: <Document />},
    {path: "/report" , component: <Report />},
    {path: "/products" , component: <ProductApprove />},
    {path: "/transactions" , component: <Transactions />},
    {path: "/transfer" , component: <Transfer />},
]
const publicRoutes = [
    { path: '/', component: <Login />}
]
export { authProtectedRoutes, publicRoutes }