import React, { useEffect } from "react"
import { useState, useMemo, useRef } from "react";

import { Container, Row, Col } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import { MdOutlineInsertPhoto } from "react-icons/md";

import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Link } from "react-router-dom";
import { IoChevronBack } from "react-icons/io5";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import profilePic from "./blank-profile-picture.jpg"
import { IoSend } from "react-icons/io5";
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { IoIosCloseCircleOutline } from "react-icons/io";
import { GoDotFill } from "react-icons/go";

import LightBox from "./LightBox";


const formatTimestamp = (timestampString) => {
    const timestamp = new Date(timestampString);

    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true
    };

    return timestamp.toLocaleString('en-US', options);
};
const Chat = () => {

    const [roomId, setRoomId] = useState(0);
    const [currentUser, setCurrentUser] = useState("");
    const [currentUserImg, setCurrentUserImg] = useState(profilePic);
    const [userId, setUserId] = useState("");
    const [crmId, setCrmId] = useState(-1);
    const [recipientId, setRecipientId] = useState(-1);
    const [recipientType, setRecipientType] = useState("");
    const [crmName, setCrmName] = useState("");
    const [message, setMassage] = useState("");
    const divRef = useRef(null);
    const [show, setShow] = useState(false);
    const [imgSrc, setImgSrc] = useState("");
    // const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    // useEffect(() => {

    //     const isLoggedIn = localStorage.getItem('isLoggedIn');
    //     if (!isLoggedIn) {
    //         history("/")
    //     } else {
    //         setLoggedInStatus(isLoggedIn);
    //     }
    // }, [history])

    // const chatSessions = testData.sessions
    const closeChat = () => {
        const finalUrl = `${process.env.REACT_APP_API_URL}/chat_api/close_crm_chat/?session_id=${roomId}`
        return axios.post(finalUrl, { withCredentials: true })
    }
    const closeChatMutation = useMutation({
        mutationFn: closeChat,
        onSuccess: (data) => {
            console.log(data);
            sessionRefetch()
            setRoomId(0)
        },
        onError: (error) => {
            console.log(error);
        }
    })
    const getCrmSessions = () => {
        const finalUrl = `${process.env.REACT_APP_API_URL}/chat_api/crm_sessions/`
        return axios.get(finalUrl, { withCredentials: true })
    }
    const {
        isLoading,
        isError,
        error,
        data,
        refetch: sessionRefetch
    } = useQuery({ queryKey: ["crm-sessions"], queryFn: getCrmSessions })
    let sessions
    if (isLoading) {
        console.log("loading");
    } else if (isError) {
        console.log(error);
    } else {
        console.log(data.data.sessions);
        sessions = data.data.sessions
    }
    useMemo(() => {
        setUserId(data?.data?.user_id);
    }, [data])
    const getChatHistory = () => {
        const finalUrl = `${process.env.REACT_APP_API_URL}/chat_api/crm_chat_history/${roomId}`
        return axios.get(finalUrl, { withCredentials: true })
    }
    const {
        isLoading: historyIsLoading,
        isError: historyIsError,
        error: historyError,
        data: historyData,
        refetch: chatHistoryRefetch
    } = useQuery({ queryKey: ["chat-history", roomId], queryFn: getChatHistory });
    let chatHistory
    if (historyIsLoading) {
        chatHistory = []
    } else if (historyIsError) {
        console.log(historyError);
    } else {
        console.log(historyData?.data);
        chatHistory = historyData?.data;
    }
    const { sendMessage, lastMessage, readyState } = useWebSocket(`wss://butikocargo.com/chat_api/adminsocket/${userId}`,
        {
            onMessage: (e) => {
                chatHistoryRefetch()
                console.log(JSON.parse(e.data).message.from_seller_name);
                toast.info(`Yeni mesaj ${JSON.parse(e.data).message.from_seller_name} dan alındı!`)
                sessionRefetch()
            }
        })
    const connectionStatus = {
        [ReadyState.CONNECTING]: 'Connecting',
        [ReadyState.OPEN]: 'Open',
        [ReadyState.CLOSING]: 'Closing',
        [ReadyState.CLOSED]: 'Closed',
        [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
    }[readyState];
    console.log(connectionStatus);

    useEffect(() => {
        if (lastMessage !== null) {
            console.log(lastMessage.data);
            
        }
    }, [lastMessage]);
    useEffect(() => {
        const scrollDiv = divRef.current;
        if (scrollDiv) {
            // Scroll to the bottom of the div
            scrollDiv.scrollTop = scrollDiv.scrollHeight - scrollDiv.clientHeight;
        }
    }, [chatHistory]);

    const sendMsg = () => {
        if (message !== "") {

            const finalData = {
                recipient: recipientId,
                recipient_type: recipientType,
                message: {
                    from_crm_name: crmName,
                    from_crm_id: crmId,
                    text: message
                }
            }
            console.log(finalData);
            sendMessage(JSON.stringify(finalData), []);
            setMassage("");
            chatHistoryRefetch()
        }

    }
    const uploadMedia = (img) => {
        const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/media/upload_files`
        const finalData = new FormData()
        finalData.append('files', img)
        return axios.post(finalUrl, finalData, {
            withCredentials: true, onUploadProgress: (ProgressEvent) => {
                const { loaded, total } = ProgressEvent
                let percent = Math.floor((loaded * 100) / total)
                console.log(`${loaded} b of ${total} b | ${percent}%`);

            }
        })
    }
    const [imgUrl, setImgUrl] = useState("");

    const uploadMediaMutation = useMutation({
        mutationFn: uploadMedia,
        onSuccess: (data) => {
            setImgUrl(data.data.file_name[0])
            const finalData = {
                recipient: recipientId,
                recipient_type: recipientType,
                message: {
                    from_crm_name: crmName,
                    from_crm_id: crmId,
                    text: "",
                    media: data.data.file_name[0]
                }
            }
            sendMessage(JSON.stringify(finalData), []);
            console.log(data);
            chatHistoryRefetch()

        },
        onError: (error) => { console.log(error); }
    })
    const updateCrmStatus = (id) => {
        const finalUrl = `${process.env.REACT_APP_API_URL}/chat_api/update_status_crm/?session_id=${id}`
        return axios.post(finalUrl, { withCredentials: true })
    }
    const updateCrmStatusMutation = useMutation({
        mutationFn: updateCrmStatus,
        onSuccess: (data) => {
            console.log(data);
        },
        onError: (error) => {
            console.log(error);
        }
    })

    return (
        <>
            <Container style={{ position: "relative", flexDirection: "row", flexWrap: "wrap" }} >
                <Link
                    style={{ position: "absolute", top: "5px ", left: "0", padding: "16px" }}
                    to={"/nextpageoflogin"}
                >
                    <IoChevronBack color="#000" size={50} />
                </Link>
                <Row className="d-flex justify-content-center align-items-center flex-row mb-5 mt-5 ">
                    <Col className="col-4 w-30 ">

                        <Image src="https://butiko-public-media-storage1.s3.eu-central-1.amazonaws.com/logo.png" fluid />
                    </Col>
                </Row>
                <Row className="">
                    <Col style={{ backgroundColor: "rgba(255,255,255,0.2)", borderRadius: "8px", overflowX: "hidden", overflowY: "scroll", height: "70vh" }} className="col-4   mb-5 ">
                        {sessions?.map((item, index) => {
                            return (
                                <Row onClick={() => {
                                    console.log(item);
                                    setCrmName(`${item.crm_first_name} ${item.crm_last_name}`)
                                    setCrmId(item.crm_id)
                                    setRecipientId(item.user_id)
                                    setRecipientType(item.sender_type)
                                    setRoomId(item._id)
                                    setCurrentUser(`${item.user_first_name} ${item.user_last_name}`)
                                    item.user_profile_picture ? setCurrentUserImg(item.user_profile_picture) : setCurrentUserImg(profilePic)
                                    updateCrmStatusMutation.mutate(item._id)
                                    sessionRefetch()

                                }} className="chat-item" key={index}>
                                    <Col className="col-12 p-1 my-1 ">
                                        <Row className="me-1">
                                            <Col className="col-2 d-flex align-items-start ">
                                                <Image style={{ minHeight: "50px", minWidth: "50px" }} src={item.user_profile_picture ? item.user_profile_picture : profilePic} fluid roundedCircle />
                                            </Col>
                                            <Col className="col-8 d-flex align-items-center">
                                                <Col >
                                                    <Row >
                                                        <h5 style={{ overflow: "hidden" }} className="text-truncate">
                                                            {item.user_first_name} {item.user_last_name}
                                                            {item.customer_status === "pending" && <GoDotFill color="#237AE7" />}
                                                        </h5>
                                                    </Row>
                                                    <Row>
                                                        <p style={{maxWidth: "50%", overflow: "hidden"}} className=" text-truncate "> {item?.recent_messages[0]?.message?.text}</p>
                                                    </Row>
                                                </Col>
                                            </Col>

                                            <Col className="col-2 d-flex align-items-center m-0 p-0  ">
                                                <span> {new Date(item?.recent_messages[0]?.timestamp).toLocaleTimeString('en-US', { hour12: false })} </span>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            )
                        })}

                    </Col>
                    <Col style={{ borderRadius: "0 8px 8px 0", }} className="col-8 bg-white mb-5">
                        <div className="chat ">
                            <div style={{ height: "13vh" }} className="chat-header clearfix">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <img src={currentUserImg} alt="avatar" />
                                        <div classname="chat-about">
                                            <h6 className="m-b-0 ms-5  ">{currentUser}</h6>

                                        </div>
                                    </div>
                                    <div className="col-lg-6 hidden-sm text-end">
                                        <IoIosCloseCircleOutline onClick={() => {

                                            closeChatMutation.mutate()
                                        }} cursor="pointer" size={34} color="#d1001f" />


                                    </div>

                                </div>
                            </div>
                            <div ref={divRef} style={{ overflowY: "scroll", overflowX: "hidden", height: "46vh" }} className="chat-history">
                                <Row>

                                    <ul className="m-b-0">

                                        {chatHistory ?
                                            (
                                                chatHistory.map((item, index) => {
                                                    return (
                                                        <>
                                                            <li key={index} className="clearfix">
                                                                <div className={`message ${item.message.from_crm_name ? ("other-message float-right") : ("my-message")} `}>
                                                                    {/* Hi Aiden, how are you? How is the project coming along? */}
                                                                    {item?.message?.media && (
                                                                        <>
                                                                            <Image width={250} onClick={()=>{
                                                                                handleShow()
                                                                                setImgSrc(item?.message?.media)
                                                                                console.log(show);
                                                                                }} src={item.message.media} fluid />
                                                                            {/* <Modal show={show} onHide={handleClose}>

                                                                                <Modal.Body>
                                                                                    <Image src={item?.message?.media} fluid />
                                                                                </Modal.Body>

                                                                            </Modal> */}
                                                                        </>

                                                                    )}
                                                                    <p>{item.message.text}</p>

                                                                    <div className=" text-right">
                                                                        <span className="message-data-time">{formatTimestamp(item.timestamp)}</span>
                                                                    </div>
                                                                </div>
                                                            </li>

                                                        </>

                                                    )
                                                })
                                            )
                                            :
                                            (null)
                                        }



                                    </ul>
                                </Row>
                            </div>
                            <Row>


                                <div style={{ height: "10vh" }} className="chat-message clearfix">
                                    <div style={{ position: "relative" }} className="input-group mb-0">

                                        <input
                                            value={message}
                                            onChange={(e) => setMassage(e.target.value)}
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter text here..."
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    sendMsg()
                                                }
                                            }}
                                        />

                                        <label htmlFor="imageInput"><MdOutlineInsertPhoto style={{ right: "60", top: "5", position: "absolute" }} size={26} /></label>


                                        <input type="file" id="imageInput" onChange={(e) => {
                                            uploadMediaMutation.mutate(e.target.files[0])
                                        }} className="d-none" />
                                        <div onClick={sendMsg} className="input-group-prepend ">
                                            <span className="input-group-text"><IoSend size={24} /></span>
                                        </div>
                                    </div>
                                </div>
                            </Row>
                        </div>

                    </Col>
                    <LightBox modalOpen={show} setModalOpen={setShow} src={imgSrc} />
                </Row>
                <ToastContainer />
            </Container>
        </>
    )
}
export default Chat