import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

const useGetPendingProducts = (page) => {
    // const [page, setPage] = useState(initialPage);

    const getPendingProducts = () => {
        const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/admin-dashboard/products/get-pending-products?page=${page}`;
        return axios.get(finalUrl, { withCredentials: true });
    };

    const { data, isLoading, isError, error, refetch } = useQuery({queryKey: ["get-pending-products",page],queryFn:getPendingProducts});

    const products = data?.data;
    console.log(products);
    return {
        products,
        isLoading,
        isError,
        error,
        refetch
    };
};

export default useGetPendingProducts;