import React from "react";
// import { useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import axios from "axios";
const Authmiddleware = (props) => {
  const history = useNavigate()
  // let location = useLocation();
  const isAuth = () => {
    const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/auth/is-authenticated`
    return axios.get(finalUrl, { withCredentials: true })
  }
  const { isLoading, isError, error: authError, data } = useQuery({ queryKey: ["is-auth"], queryFn: isAuth });
  let status
  if (isLoading) {
    status = "Checking your authentication..."
  } else if (isError) {
    console.error("Error checking authentication:", authError);
  } else {
    status = data?.data
    console.log(data?.data);
    if (!status.is_authenticated) {
      history("/")
    }
    
  }
  


  return (
    isLoading ? null : (
      <React.Fragment>
        {props.children}
        {/* <ToastContainer /> */}
      </React.Fragment>
    )
  );
  
};

export default Authmiddleware;