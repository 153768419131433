export default function formatDate(dateString) {
    // Create a new Date object from the date string
    const date = new Date(dateString);

    // Options for formatting the date
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false, // Use 24-hour format
    };

    // Format the date using toLocaleString with Turkish locale
    const formattedDate = date.toLocaleString('tr-TR', options);

    return formattedDate;
}
