import React from "react"
import { useState } from "react";
import Button from 'react-bootstrap/Button';
import { Container, Row, Col } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';

import { Link } from "react-router-dom";
import { IoChevronBack } from "react-icons/io5";
import {  ToastContainer } from 'react-toastify';
import Table from 'react-bootstrap/Table';
import useReportSummary from "./hooks/useReportSummary";
import "react-toastify/dist/ReactToastify.css";
import { Skeleton } from "antd";
import ReportModal from "./modal/reportModal";
import formatDate from "../../components/formatDate";
const Report = () => {
    const [show, setShow] = useState(false);
    const [productId, setProductId] = useState(0);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const {

        page,
        setPage,
        reportSummaryData,
        isLoading,
    } = useReportSummary();
    const handleBack = () => {
        if (page !== 0) {
            setPage(page - 1)
        }
    }
    const handleNext = () => {
        if (reportSummaryData.length === 11) {
            setPage(page + 1)
        }
    }
    // console.log(isLoading);
    return (
        <>
            {/* <Container style={{ position: "relative", flexDirection: "row", flexWrap: "wrap" }} className="d-flex justify-content-center vh-100 vw-100 "> */}
            <Container >
                <Link
                    style={{ position: "absolute", top: "5px ", left: "0", padding: "16px" }}
                    to={"/nextpageoflogin"}
                >
                    <IoChevronBack color="#000" size={50} />
                </Link>
                <Row className="d-flex justify-content-center align-items-center flex-row mb-5 mt-5 ">
                    <Col className="col-4 w-30 ">

                        <Image src="https://butiko-public-media-storage1.s3.eu-central-1.amazonaws.com/logo.png" fluid />
                    </Col>
                </Row>

                <Row className="d-flex justify-content-center align-items-center   ">
                    <Col className="col-12">
                        <h1>filter</h1>
                    </Col>
                    {isLoading ?

                        (
                            <>
                                <Skeleton active />
                            </>
                        ) :
                        (
                            <>
                                <Col className="d-flex justify-content-center align-items-center ">
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>product_id</th>
                                                <th>total_reports</th>
                                                <th>last_reported_at</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {reportSummaryData && reportSummaryData.length > 0 &&
                                                reportSummaryData.slice(0, reportSummaryData.length === 11 ? -1 : reportSummaryData.length).map((report, index) => {
                                                    return (
                                                        <tr
                                                            key={index}
                                                            onClick={() => {
                                                                handleShow();
                                                                setProductId(report.product_id);
                                                            }}>
                                                            <td>{index + 1}</td>
                                                            <td>{report.product_id}</td>
                                                            <td>{report.total_reports}</td>
                                                            <td>{formatDate(report.last_reported_at)}</td>
                                                        </tr>
                                                    );
                                                })
                                            }


                                        </tbody>
                                    </Table>


                                </Col>
                                <Col className="col-12 d-flex justify-content-end">
                                    <Button onClick={handleBack} className="btn btn-secondary me-1">back</Button>
                                    <Button onClick={handleNext} className="btn btn-secondary">next</Button>
                                </Col>
                            </>
                        )}
                </Row>
                <ToastContainer />
                <ReportModal
                    show={show}
                    onHide={handleClose}
                    productId={productId}
                />
            </Container>
        </>
    );
}
export default Report